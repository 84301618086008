<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12 mb-3">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Titulo</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.title"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mb-3">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>Setup</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Tipo</label>
                                    <div class="col-sm-9">
                                        <select
                                            class="form-select btn-square digits"
                                            v-model="state.setup.type"
                                        >
                                            <option disable></option>
                                            <option value="String">Cadena de Texto</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Input</label>
                                    <div class="col-sm-9">
                                        <select
                                            class="form-select btn-square digits"
                                            v-model="state.setup.input"
                                        >
                                            <option disable></option>
                                            <option value="text">Text</option>
                                            <option value="fileUpload">File Upload</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mb-3">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>Valor</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row" v-if="state.setup.input">
                                    <label class="col-sm-3 col-form-label">Value</label>
                                    <div class="col-sm-9" v-if="state.setup.input === 'text'">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.value"
                                        />
                                    </div>
                                    <div
                                        class="col-sm-9"
                                        v-else-if="state.setup.input === 'fileUpload'"
                                    >
                                        <FileInput
                                            class="form-control"
                                            type="text"
                                            v-model="state.value"
                                            :file="state.value"
                                            :key="fileInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('save')"
                                v-if="state.id"
                            >
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('create')"
                            >
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('create_view')"
                            >
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pre v-if="env === 'development'">{{ JSON.stringify(state, null, 4) }}</pre>
    </WindowLayout>
</template>
<script>
import { onMounted, ref } from 'vue';

// Components
import FileInput from '@/components/form/FileInput.vue';
import WindowLayout from '@/layouts/Window.layout.vue';

// Services
import useParameters from './services/useParameters';
import useParametersForm from './services/useParametersForm';

export default {
    name: 'ParametersForm',
    components: {
        WindowLayout,
        FileInput,
    },
    props: {
        title: { type: String, default: 'Parametro de Configuracion' },
        headerTitle: { type: String, default: 'Parametro' },
        urlRead: { type: String, required: true },
        urlCreate: { type: String, required: false },
        urlUpdate: { type: String, required: false },
        item: Object,
    },
    setup(props) {
        const fileInput = ref(Date.now());

        const parametersService = useParameters();
        // eslint-disable-next-line object-curly-newline
        const { state, setState, v, handleAction } = useParametersForm(parametersService);

        onMounted(async () => {
            if (props.item) {
                await Promise.all([parametersService.getParameter(props.item.id)]);
                if (parametersService.parameter.value) {
                    setState(parametersService.parameter.value);
                }
            }
        });

        return {
            fileInput,
            state,
            handleAction,
            v,
            env: process.env.NODE_ENV,
        };
    },
};
</script>
<style lang="scss" scoped></style>
