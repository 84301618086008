import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function useParameters() {
    const parameter = ref(null);
    const parameters = ref([]);

    const createParameter = async (data) => {
        const res = await axios.post('/parameters', data);
        if (res.status === 201) {
            parameter.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getParameter = async (id) => {
        const res = await axios.get(`/parameters/${id}`);
        if (res.status === 200) {
            parameter.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getParameters = async (searchQuery) => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/parameters${query}`);
        if (res.status === 200) {
            parameters.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateParameter = async (id, data) => {
        const res = await axios.put(`/parameters/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            parameter.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        parameter,
        parameters,
        createParameter,
        getParameter,
        getParameters,
        updateParameter,
    };
}
