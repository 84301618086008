import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useSnackbar } from 'vue3-snackbar';

export default function useParametersForm(service) {
    const store = useStore();
    const snackbar = useSnackbar();

    const state = reactive({
        id: '',
        name: '',
        title: '',
        value: '',
        setup: {},
        status: 1,
    });

    const setState = (c) => {
        state.id = c.id;
        state.title = c.title;
        state.name = c.name;
        state.value = c.value;
        state.setup = c.setup;
        state.status = c.status;
    };

    const rules = computed(() => ({
        name: { required },
        title: { required },
        value: { required },
        setup: { required },
    }));

    const v = useVuelidate(rules, state);

    const resetForm = () => {
        v.value.$reset();
        state.id = '';
        state.name = '';
        state.title = '';
        state.value = '';
        state.setup = '';
        state.status = 1;
    };

    const handleAction = async (action) => {
        if (await v.value.$validate()) {
            let text;
            switch (action) {
                case 'create':
                case 'create_view':
                    await service.createParameter(state);
                    if (action === 'create_view') {
                        store.dispatch('ContentManager/openWindow', {
                            id: `ParametersForm_${service.parameter.value.id}`,
                            component: 'ParametersForm_',
                            name: service.parameter.value.title,
                            params: {
                                title: 'Parametro',
                                headerTitle: `Ver ${service.parameter.value.title}`,
                                item: { ...service.parameter.value },
                            },
                        });
                    }
                    text = 'Parametros credo con exito';
                    resetForm();
                    break;
                case 'save':
                    await service.updateParameter(state.id, state);
                    text = 'Parametro actualizado con exito';
                    break;
                default:
                    break;
            }
            snackbar.add({
                type: 'success',
                text,
            });
        } else {
            console.log(v.$errors);
            snackbar.add({
                type: 'error',
                text: 'Ups no podemos enviar la informacion sin algunos datos',
            });
        }
    };

    return {
        v,
        state,
        setState,
        resetForm,
        handleAction,
    };
}
